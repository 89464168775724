<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div v-show="!processDisabled" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="WORK_IMPORTANT_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="workImportantCd"
            label="작업중요도"
            v-model="searchParam.workImportantCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="안적작업표준"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      :isExcelDown="false"
      :isExpan="false"
      :isFullScreen="false"
      :columnSetting="false"
      rowKey="mdmSwsId"
      @linkClick="openPop"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'sws-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: null,
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      searchParam: {
        plantCd: null,
        workImportantCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장명',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          // {
          //   name: 'swsNo',
          //   field: 'swsNo',
          //   label: '안전작업표준 관리번호',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width: 160px',
          // },
          {
            name: 'swsName',
            field: 'swsName',
            label: '안전작업표준명',
            align: 'left',
            sortable: true,
            type: 'link'
          },
          {
            name: 'workImportantName',
            field: 'workImportantName',
            label: '작업중요도',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'termTm',
            field: 'termTm',
            label: '작업예상시간',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'purpose',
            field: 'purpose',
            label: '목적',
            align: 'left',
            style: 'width: 300px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
        ],
        data: [],
        height: '400px',
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  methods: {
    init() {
      this.listUrl = selectConfig.mdm.sws.job.list.url;
      this.searchParam.plantCd = this.popupParam.plantCd;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '안전작업표준을 선택하세요.', // 안전작업표준을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    openPop(row) {
      this.popupOptions.title = '안전작업표준 상세'; // 작업허가서 상세
      this.popupOptions.param = {
        mdmSwsId: row ? row.mdmSwsId : '',
        plantCd: row ? row.plantCd : '',
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/sws/workStandardDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '99%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
